import React from 'react'
import HeroBannerResize from '../assets/HeroBannerResize.jpeg'
import { HiArrowNarrowRight } from 'react-icons/hi'
import { Link } from 'react-scroll'

const Hero2 = () => {

  return (

    <div className='w-screen h-screen relative'>
      <img className='w-full h-screen object-cover' src={HeroBannerResize} alt='HeroFarinella' />
      <div className='absolute h-screen inset-0  bg-black opacity-40' />
      <div className='absolute w-full h-full flex flex-col justify-center top-0 text-slate-50' >
        <div className='ml-12 lg:ml-36 mb-20'>
          <p className='text-2xl'>Transform Your Home</p>
          <h1 className='py-3 text-5xl md:text-7xl font-bold'>With Expert Landscaping</h1>
          <p className='text-2xl'>On time, with precision.</p>
          {/* <button className='py-4 px-10 mt-2 my-4 text-green-700 bg-green-100 border-green-100 rounded-full
                   hover:bg-green-200  hover:border-green-700 transition duration-300 ease-in-out'>
                  Get a Free Quote Today!
                </button> */}
          <Link to="Quote" className='' smooth={true} offset={0} duration={500}>
            <button className='py-4 px-10 mt-2 my-4 text-green-700 bg-green-100 border-green-100 rounded-full
                   hover:bg-green-200  hover:border-green-700 transition duration-300 ease-in-out group border-2 flex items-center'>
              Get a Quote!
              <span className='group-hover:rotate-90 duration-300'><HiArrowNarrowRight className='ml-4' /></span>
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Hero2