import React from 'react'
import PaversResize from '../assets/PaversResize.jpeg'
import { ImLocation2 } from 'react-icons/im'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { GoMailRead } from 'react-icons/go'
import { FaFacebook, FaInstagram } from 'react-icons/fa'

const Contact = () => {
  return (
    <div name='Contact' className='w-full mt-24'>
      <div className='w-full h-[500px] bg-gray-900/90 absolute'>
        <img className='w-full h-[500px] object-cover mix-blend-overlay' src={PaversResize} alt="/" />
      </div>

      <div className='max-w-[1240px] h-[500px] mx-auto text-white relative'>

        <div className='grid grid-cols-2 px-4 py-12'>
          <h2 className='text-4xl pt-8 uppercase text-center text-slate-200'>Contact</h2>
          <h3 className='text-4xl pt-8 uppercase text-center text-slate-200'>Hours</h3>
          <p className='py-4 text-3xl text-slate-300'></p>
        </div>
        <div className='grid grid-cols-2 px-4 py-4 '>
          <div>

            <p className='text-l pb-6 text-center text-slate-100'><ImLocation2 className='inline-block mr-3 mb-2 text-2xl text-slate-100' />Bensalem, Pennsylvania</p>
            <p className='text-l pb-6 text-center text-slate-100'><BsFillTelephoneFill className='inline-block mr-3 text-2xl text-slate-100' />(267) 437-7764</p>
            <p className='text-l pb-6 text-center text-slate-100'><GoMailRead className='inline-block mr-3 mb-2 text-2xl text-slate-100 ' />Farinellaslawnandlandscape@gmail.com</p>
          </div>
          <div>
            <p className='text-l pb-6 text-center text-slate-100'>Monday-Friday: 7am-5pm</p>
            <p className='text-l pb-6 text-center text-slate-100'>Closed: Saturday & Sunday</p>
          </div>
        </div>
        <div>
          <div className='flex flex-col justify-center text-center'>
            <h4 className='text-center'>Follow Us!</h4>
            <div className='flex justify-center pt-2'>
              <a href='https://www.facebook.com/Farinellas.Lawn.and.Landscape.LLC/'><FaFacebook className='text-4xl mr-2 hover:text-slate-300' /></a>
              <a href='https://www.instagram.com/farinellas.lawn.and.landscape/?hl=en'><FaInstagram className='text-4xl hover:text-slate-300' /></a>
            </div>

          </div>
        </div>
      </div>

    </div>
  )
}

export default Contact