import React from 'react'
import FlagRockBedResize from '../assets/FlagRockBedResize.jpeg'
import CroppedIconResize from '../assets/CroppedIconResize.png'
import { toast } from "react-hot-toast"


const QuoteForm = () => {

  async function handleSubmit(e) {
    e.preventDefault();
    const formData = {};
    Array.from(e.currentTarget.elements).forEach(field => {
      if (!field.name) return;
      formData[field.name] = field.value;
    });

    try {
      const response = await fetch('https://rqnts9e1cd.execute-api.us-west-2.amazonaws.com/PROD', {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      console.log('Form data submitted:', formData);
      toast.success('Quote Successfully Sent')
      
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('Error Submitting Form');
    }
};

return (
  <div className='mt-20 grid md:grid-cols-2 max-w-[1240px] m-auto gap-10 p-auto'>
    <div className='flex flex-col justify-center items-center mb-12 ml-4'>
      <img className='h-[300px] mb-16' src={CroppedIconResize} alt='Icon' />
      <img className='w-full rounded-lg hover:scale-110 duration-700' src={FlagRockBedResize} alt='HeroFarinella' />
    </div>
    <div name="Quote" className='w-full h-screen flex justify-center items-center p-4'>
      <form onSubmit={handleSubmit} method='POST' className='flex flex-col max-w max-w-[600px] w-full '>
        <div className='text-center pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-emerald-700'>Get a Free Quote!</p>
          <p className='text-gray-600 py-4'>Submit the form below for a free estimate, we will get back to you ASAP!</p>
          <p className='italic'><span className='underline font-bold'>Need a faster reply?</span> Call or Text (267) 437-7764 and leave a message detailing your needs! Or email us at <span cl>Farinellaslawnandlandscape@gmail.com</span></p>
        </div>
        <input required className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='First Name' name='firstName' />
        <input className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='Last Name' name='lastName' />
        <input required className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='City' name='city' />
        <input required className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='Email Address' name='email' />
        <input required className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='Phone Number' name='phone' />
        <input required className='mb-7 border-[2.5px] border-green-800 p-2 rounded-full focus:outline-emerald-500' type="text" placeholder='Services Requested' name='servicesRequested' />
        <textarea className='mb-7 border-[2.5px] border-green-800 p-2 rounded-md focus:outline-emerald-500' type="text" placeholder='Additional Details...' name='additionalDetails' rows="3" />
        <div className='flex flex-col p-2 items-center'>
          <button className='py-3 px-6 sm:w-[80%] my-4 md:w-[100%]'>Submit</button>
        </div>
      </form>
    </div>
  </div>
)
}

export default QuoteForm