import React from 'react'
import { Link } from 'react-scroll'
import { useState } from 'react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'

import Icon from '../assets/Icon.png'

const Navbar = () => {

  const [showNav, setShowNav] = useState(false)

  const handleClick = () => {
    setShowNav(!showNav)
  }

  const handleClose = () => {
    setShowNav(false)
  }

  const phoneNumber = '2674377764';

  const handleButtonClick = () => {
    window.open(`tel:${phoneNumber}`);
  };

  return (
    <div name='Home' className='relative z-10'>
      <div className='w-screen h-[80px] z-10 bg-green-50 drop-shadow-lg'>
        <div className='px-2 flex justify-between items-center w-full h-full'>
          <div className='flex items-center'>
            {/* <h1 className='text-lg font-bold mr-4 sm:text-2xl'>Farinella's Lawn & Landscape</h1> */}
            <img className='w-[90px] mx-6 mt-1' src={Icon} alt='Icon' />
            <ul className='hidden md:flex'>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Home" className='hover:cursor-pointer hover:text-green-900' smooth={true} duration={500}>Home</Link></li>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Services" className='hover:cursor-pointer hover:text-green-900' smooth={true} offset={5} duration={500}>Services</Link></li>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Quote" className='hover:cursor-pointer hover:text-green-900' smooth={true} offset={0} duration={500}>Get a Quote!</Link></li>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Portfolio" className='hover:cursor-pointer hover:text-green-900' smooth={true} offset={0} duration={500}>Portfolio</Link></li>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Testimonials" className='hover:cursor-pointer hover:text-green-900' smooth={true} offset={-50} duration={500}>Testimonials</Link></li>
              <li className='hover:before:scale-x-100 hover:before:origin-left relative before:w-full before:h-1 before:origin-right before:transition-transform before:duration-300 before:scale-x-0 before:bg-green-700 before:absolute before:left-0 before:bottom-0'><Link to="Contact" className='hover:cursor-pointer hover:text-green-900' smooth={true} offset={50} duration={500}>Contact</Link></li>
            </ul>
          </div>
          <div className='hidden lg:flex pr-4'>
            <button onClick={handleButtonClick} className='px-8 py-3'>Call Today!</button>
          </div>
          <div className='lg:hidden mr-4'
            onClick={handleClick}>
            {!showNav ? <MenuIcon className='w-5' /> : <XIcon className='w-5' />}
          </div>
        </div>

        <ul className={!showNav ? 'hidden' : 'absolute bg-green-50 w-full px-8 text-center'}>
          <li><Link onClick={handleClose} to="Home" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} duration={500}>Home</Link></li>
          <li><Link onClick={handleClose} to="Services" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} offset={-200} duration={500}>Services</Link></li>
          <li><Link onClick={handleClose} to="Quote" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} offset={50} duration={500}>Get a Quote!</Link></li>
          <li><Link onClick={handleClose} to="Portfolio" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} offset={0} duration={500}>Portfolio</Link></li>
          <li><Link onClick={handleClose} to="Testimonials" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} offset={-50} duration={500}>Testimonials</Link></li>
          <li><Link onClick={handleClose} to="/" className='hover:cursor-pointer border-b-2 border-green-700 hover:text-green-900' smooth={true} offset={50} duration={500}>Contact</Link></li>
          <div className='flex flex-col my-4 p-8'>
            <button onClick={handleButtonClick} className='px-8 py-3 mb-4' te>Call Today</button>
          </div>
        </ul>

      </div>
    </div>
  )
}

export default Navbar